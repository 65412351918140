import "core-js/stable";
import "regenerator-runtime/runtime";

import { Power2, gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';

// import { ScrollItem__SliderScrollHorizontal } from './scroll/ScrollItem__SliderScrollHorizontal';
// import { ScrollItem__ProjectList } from './scroll/ScrollItem__ProjectList';
// import { ScrollItem__BillboardProject } from './scroll/ScrollItem__BillboardProject';
// import { ScrollItem__SliderDefault } from './scroll/ScrollItem__SliderDefault';
// import { ScrollItem__SliderCircular } from './scroll/ScrollItem__SliderCircular';
import { ScrollItem__WidgetGallery } from './scroll/ScrollItem__WidgetGallery';
import { ScrollItem__PlayerVideo } from './scroll/ScrollItem__PlayerVideo';
import { ScrollItem__WidgetLanding } from './scroll/ScrollItem__WidgetLanding';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Legal from './pages/Legal';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';

import Preloader from "./layout/Preloader";
import Language from "./_app/cuchillo/utils/Language";
import MusicController from "./controllers/MusicController";
import GUIController from "./controllers/GUIController";
import WebGLSketch from "./_app/cuchillo/3D/WebGLSketch";
import Scene from "./3D/Scene";
import Sidemenu from "./layout/Sidemenu";

export default class Main {
  static stats;

  static init() {
    Basics.id = "w11p_v008"; // ID para cookies   
    Language.init();

    Header.init();
    Sidemenu.init();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    // Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    // ControllerWindow.init(); // Control ventanas

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    MusicController.init();
    GUIController.init();
    Scene.init();
    WebGLSketch.init();

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.update = progress => Preloader.update(progress);
    LoaderController.onComplete = () => Main.setup();

    this.setWorker();

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      this.doCuchilloInfo();
      gsap.ticker.add(() => { Main.loop(); });
    }

    Preloader.init();
    LoaderController.init();
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener('video-play', () => {
      MusicController.stop();
    });

    EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
      Scroll.setEnabled(false);
    });
  }

  static resize() {
    // Header.resize();
    Sidemenu.resize();
    InterfaceCanvas.resize();
    GUIController.resize();
    BG.resize();
    ControllerPage.resize();
    WebGLSketch.resize();

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    if (Scroll.isScrolling) Scroll.loop();

    // ControllerPage.loop();
    BG.loop();
    // Header.loop();
    Sidemenu.loop();
    InterfaceCanvas.loop();
    GUIController.loop();
    MusicController.loop();
    WebGLSketch.loop();
    Scene.loop();
  }

  static loopDebug() {
    // Statics.begin();
    this.loop();
    // Statics.end();
  }

  static doCuchilloInfo() {
    console.log('%cby Jose Sentis & Santa Helena', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://josesentis.com');
    console.log('⟶ https://www.instagram.com/jose.sentis');
    console.log('');
    console.log('⟶ https://santahelena.agency');
    console.log('⟶ https://www.instagram.com/santahelena.agency');
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
